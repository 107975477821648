<template>
  <div class="flex">
    <a
      v-for="socialLink in getSocialLinks()"
      :key="socialLink.link"
      :href="socialLink.link"
      rel="external, noindex, nofollow"
      :aria-label="socialLink.icon"
      target="_blank"
      class="w-8 h-8 flex items-center justify-center"
    >
      <i
        :class="`icon-${
          socialLink.icon === 'personalSite'
            ? 'personalSiteglobe'
            : socialLink.icon === 'twitter'
            ? 'x'
            : socialLink.icon
        } text-base text-grayscale-96`"
      />
    </a>
  </div>
</template>

<script setup lang="ts">
import { ProSocialLinkProvider, User } from "@/types/user";
import { PropType } from "vue";

const props = defineProps({
  user: { type: Object as PropType<User>, required: true },
});

const getSocialLinks = (): { link: string; icon: any }[] => {
  const socialLinks = props.user?.socialLinks || {};

  const escapedLabels = {
    spotify: "",
    soundcloud: "",
    instagram: "https://www.instagram.com/",
    twitter: "https://www.x.com/",
    tiktok: "https://www.tiktok.com/@",
    twitch: "https://www.twitch.tv/",
    linkedin: "https://www.linkedin.com/in/",
    youtube: "https://www.youtube.com/@",
    personalSite: "",
  };
  return Object.keys(socialLinks)
    .filter((key: any) => socialLinks[key as ProSocialLinkProvider])
    .map((key: any) => ({
      link: (escapedLabels[key] || "").concat(
        socialLinks[key as ProSocialLinkProvider] as string,
      ),
      icon: key,
    }));
};
</script>
